/** @jsxImportSource theme-ui */
import { jsx, Heading, Box, Text } from 'theme-ui';
import { Link } from 'components/link';
import menuItems from './footer.data';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Footer(props) {
  return (
    <footer sx={props.fixed == 'true' ? styles.footer.fixed : styles.footer}>
      <Container>
        <Row>
          <Col xs={12}>
            <Link
              path="/policies/privacy"
              label="Privacy Policy"
              sx={styles.footer.link}
            /> - <Link
              path="/policies/terms"
              label="Terms and Conditions"
              sx={styles.footer.link}
            />
            <br />
            <Text sx={styles.footer.copyright}>
              <strong>Copyright &copy; 2023 Theia Stream, LLC.</strong>
            </Text>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

const styles = {
  footer: {
    backgroundColor: '#F6F6F6',

    fixed: {
      position: 'fixed',
      left: 0,
      bottom: 0,
      width: '100%',
      backgroundColor: '#F6F6F6',
    },

    container: {
      width: '100%',
      alignItems: 'stretch',
    },

    link: {
      fontSize: ['14px', null, 1],
      fontWeight: 'body',
      color: 'text',
      cursor: 'pointer',
      textDecoration: 'none',
      ':hover': {
        color: 'primary',
      },
    },
    copyright: {
      fontSize: ['14px', null, 1],
      width: '100%',
      textAlign: 'center',
      color: 'text',
      a: {
        textDecoration: 'none',
        color: 'inherit',
        pl: 1,
        transition: 'all 0.25s',
        '&:hover': {
          color: 'primary',
        },
      },
    },
  },
};
